import { Directive , ElementRef,OnChanges,SimpleChanges, HostListener, Input, OnInit} from '@angular/core';
declare var google: any;
@Directive({
  selector: '[appPieChart]'
})
export class PieChartDirective implements OnChanges{

   @Input('') appPieChartConfig: any;
  constructor(private elRef: ElementRef) {}
/* 
  ngOnInit() {
    debugger;
    //setTimeout(() => {
    google.load("visualization", "1", {packages: ["corechart"]});
    google.setOnLoadCallback(this.drawChart(this.appPieChartConfig.ChartConfiguration, 
      this.appPieChartConfig.moduleData));
  //}, 5000); 

} */

ngOnChanges(changes: SimpleChanges){

  google.load("visualization", "1", {packages: ["corechart"]});
  google.setOnLoadCallback(this.drawChart(this.appPieChartConfig.ChartConfiguration, 
    this.appPieChartConfig.chartsdata));
}

private drawChart(ChartConfiguration, moduleData1):any {
  if(moduleData1.length !=0){

  google.load('visualization', '1.1', {packages: ['pie', 'corechart']});
  var data = new google.visualization.DataTable(moduleData1);
  /* var data = google.visualization.arrayToDataTable([
    ['Language', 'Speakers (in millions)'],
    ['German',  5.85],
    ['French',  1.66],
    ['Italian', 0.316],
    ['Romansh', 0.0791]
  ]);

var options = {
  legend: 'none',
  pieSliceText: 'label',
  title: 'Swiss Language Use (100 degree rotation)',
  pieStartAngle: 100,
}; */
var addRowData = Object.keys(moduleData1[0]);



var NewData : any;
NewData = $(addRowData).each(function(i) {
  addRowData[i];
}).get().join(',');

var array = NewData.split(',');
for (var i = 0; i < Object.keys(moduleData1[0]).length; i++)   
{  
 
  
   if(i == 0)  
   {
     data.addColumn("string",Object.keys(moduleData1[0])[i]);
 
   }
   else
   {

    data.addColumn("number",Object.keys(moduleData1[0])[i]);
   
   }
} 
for (var i = 0; i < moduleData1.length; i++)   
{ 
  var temp =[]; 
  for(var j =0; j<array.length; j++)
  {
    if(j == 0)
    {
    temp.push(moduleData1[i][array[j]]);
    }
    else 
    {
      temp.push(Number(moduleData1[i][array[j]]));
    }
   console.log(temp);
  }
  data.addRow([temp][0]);

} 
//Generalization implementation

var options = {
  title: ChartConfiguration.Title,
  chartArea:{left:'0',top:0,width:'100%',height:'90%'},
  is3D :true,
  legend:{position:'bottom'}
};

  var chart = new google.visualization.PieChart(document.getElementById(this.elRef.nativeElement.id));
  chart.draw(data, options);
}

}
}
