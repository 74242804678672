import { Directive , ElementRef, HostListener, Input, OnInit, OnChanges, SimpleChanges,} from '@angular/core';
import { deflateRaw } from 'zlib';

declare var google: any;
var timeout;
@Directive({
  selector: '[appLinechart]',
 
})

export class LinechartDirective implements OnChanges {

  @Input('') appLinechartConfig: any;

  constructor(private elRef: ElementRef) { 
   
  }

  ngOnChanges(changes: SimpleChanges){
    debugger;
    google.load("visualization", "1", {packages: ["corechart"]});
    google.setOnLoadCallback(this.drawChart(this.appLinechartConfig.ChartConfiguration, 
      this.appLinechartConfig.moduleData));
  }

private drawChart(ChartConfiguration, moduleData1) :any
{
  
  if(moduleData1.length !=0){
let data1: any;

google.load('visualization', '1.1', {packages: ['line', 'corechart']});
var data = new google.visualization.DataTable(moduleData1);
var addRowData = Object.keys(moduleData1[0]);


var NewData : any;
NewData = $(addRowData).each(function(i) {
  addRowData[i];
}).get().join(',');

var array = NewData.split(',');

for (var i = 0; i < Object.keys(moduleData1[0]).length; i++)   
{  
 
  
   if(i == 0)  
   {
     data.addColumn("string",Object.keys(moduleData1[0])[i]);
 
   }
   else
   {

    data.addColumn("number",Object.keys(moduleData1[0])[i]);
   
   }
} 

for (var i = 0; i < moduleData1.length; i++)   
{ 
  var temp =[]; 
  for(var j =0; j<array.length; j++)
  {
    if(j == 0)
    {
    temp.push(moduleData1[i][array[j]]);
    }
    else 
    {
      temp.push(Number(moduleData1[i][array[j]]));
    }
 
  
  }
  data.addRow([temp][0]);
  
} 
console.log(data);

var options = {
  title: ChartConfiguration.title,
  chartArea:{left:60,width:'92%',height:'70%'},
  is3D :true,
  legend:{position:'bottom'}
};

var materialChart = new google.visualization.LineChart(document.getElementById(this.elRef.nativeElement.id));
materialChart.draw(data, options);

}

}
}




  

