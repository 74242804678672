import { Component, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { NotificationsComponent } from './notifications/notifications.component';
import { PopoverController } from '@ionic/angular';
import { MainPageService } from '../Services/MainPage.service';
import { Badge } from '@ionic-native/badge/ngx';
import {MenuItem} from 'primeng/api';
const AppName = environment.AppName;
@Component({
  selector: 'app-header',
  templateUrl: './header.page.html',
  styleUrls: ['./header.page.scss'],
})
export class HeaderPage implements OnInit {
    name: string;
    companyname:string;
    AppName = AppName;
    badgeValue: any;
    UserName: string;
    items = [{
        label: 'Options',
        items: [
        //     {
        //     label: 'Home',
        //     icon: 'pi pi-home',
        //     command: () => {
        //         this.home();
        //     }
        // },
        {
            label: 'Help',
            icon: 'pi pi-question-circle',
            command: () => {
                //this.delete();
            }
        },
        {
            label: 'Raise Ticket',
            icon: 'pi pi-ticket',
            command: () => {
                this.TicketAppLink();
            }
        },
        
        
        ]},
        {
            label: 'Profile',
            items: [
                {
                    label: 'Go To Profile',
                    icon: 'pi pi-user',
                    command: () => {
                        this.GoToProfile();
                    }
                },
                {
                    label: 'Sign out',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.logout();
                    }
                },
        ]}
    ];

    constructor(public events: Events, private router: Router,private badge: Badge, private mainpageservice: MainPageService, public popoverController: PopoverController,
        private authentication: AuthenticationService,private _location:Location) {
        events.subscribe('PageName', (name) => {
            // user and time are the same arguments passed in `events.publish(user, time)`
            console.log('name', name);
            this.name = name;
            this.companyname = localStorage.getItem('Profile_Type');

        });
    }
    TicketAppLink(){
        window.open('http://ticketapp.datasavi.in/','_blank');
    }
     ngOnInit() {
        this.mainpageservice.GetBadgeValue(this.UserName).subscribe(response => { this.badgeValue = response });
    }
     async shownotifications(event) {
        const popover = await this.popoverController.create({ component: NotificationsComponent, event });
        return await popover.present();
    }
    GoToProfile() {
        alert('profile');
        this.router.navigate(['profile']);
    }

    logout() {
        this.authentication.logout();
    }
    home()
    {
        window.location.href="/menu/first/tabs";
    }
    back()
    {
            this._location.back();
    }
}
