import { NgModule, ModuleWithProviders } from '@angular/core';

import { HighlightDirective } from '../Directive/highlight.directive';
import {PieChartDirective} from '../Directive/pie-chart.directive'
import {LinechartDirective} from '../Directive/linechart.directive';
@NgModule({
  declarations: [
    HighlightDirective,
    PieChartDirective,
    LinechartDirective
  ],
  exports: [
    HighlightDirective,
    PieChartDirective,
    LinechartDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
     
    };
  }
}